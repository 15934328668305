import React, {useEffect} from 'react'
import styles from "./GorgonTattoo.module.css"

const GorgonTattoo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>GorgonTattoo</div>
  )
}

export default GorgonTattoo