import React from 'react'
import { useNavigate } from 'react-router-dom'
import origymCard from "../../assets/origym_card.png"
import petmatchCard from "../../assets/petmatch_card.png"
import pointisCard from "../../assets/pointis_card.png"
import styles from "./LatestProject.module.css"
import openIcon from "./Open.png"

const LatestProjects = ({t}) => {

    const navigate = useNavigate()

  return (<div className={styles.projectsContainer}> <header className={styles.title}>
      <h2>{t("recentProjects")}</h2></header>
      <section className={styles.projectsCards}>
       
        <div onClick={() => navigate("/portfolio/origym")}className={styles.projectCard}><h3 className={styles.cardTitle}>Origym<img className={styles.cardIcon} src={openIcon} alt="Open"></img></h3><img className={styles.cardImage} src={origymCard}/><p>Web/Mobile App Development and UI/UX Design</p></div>
        <div onClick={() => navigate("/portfolio/petmatch")}className={styles.projectCard}><h3 className={styles.cardTitle}>PetMatch<img className={styles.cardIcon} src={openIcon} alt="Open"></img></h3><img className={styles.cardImage} src={petmatchCard}/><p>Web/Mobile App Development and UI/UX Design</p></div>
        <div onClick={() => navigate("/portfolio/gorgon")} className={styles.projectCard}><h3 className={styles.cardTitle}>Point.Is<img className={styles.cardIcon} src={openIcon} alt="Open"></img></h3><img className={styles.cardImage} src={pointisCard}/><p>Web/Mobile App Development and UI/UX Design</p></div>
        
      </section><button onClick={() => navigate("/portfolio")}>View More</button></div>
  )
}

export default LatestProjects