
import styles from "./Skills.module.css";
import uxIcon from "./uxIcon.png"
import uiIcon from "./uiIcon.png"
import frontEndIcon from "./frontEndIcon.png"
const Skills = ({t}) => {

  return (
    <div>
     
      <section className={styles.mainSection}>
         <header className={styles.title}>
        <h2>{t("skills")}</h2>
      </header>
        <section className={styles.cards}>
          <ul className={styles.cardsList}>
            <li className={styles.card}><h3>{t("UX Design")}</h3>
            <img src={uxIcon} alt="UX Icon"></img>
            <ul>
              <li>{t("Research")}</li>
              <li>{t("Information Architecture")}</li>
              <li>{t("Wireframes")}</li>
              <li>{t("Prototyping")}</li>
              <li>{t("User Testing")}</li> 
            </ul>
            </li>
            <li className={styles.card}><h3>{t("UI Design")}</h3>
            <img src={uiIcon} alt="UI Icon"></img>
            <ul>
              <li>Figma</li>
              <li>{t("Visual Design")}</li>
              <li>{t("Design Systems")}</li>
              <li>{t("Responsive Design")}</li>
              <li>{t("Prototyping")}</li> 
            </ul>
            </li>
            <li className={styles.card}><h3>{t("Front-End")}</h3>
            <img src={frontEndIcon} alt="Front-End Icon"></img>
            <ul>
              <li>React</li>
              <li>JavaScript ES6+</li>
              <li>CSS 3</li>
              <li>HTML 5</li>
              <li>{t("Version Control")}</li> 
            </ul>
            </li>
          </ul>
        </section>
      
      </section>
    </div>
  );
}

export default Skills;