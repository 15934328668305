import React, { useState, useEffect } from 'react';
import styles from "./FeatureDisplay.module.css";
import UI from "../../assets/ui.png";
import UX from "../../assets/ux.png";
import frontEnd from "../../assets/frontEnd.png";
import { FadeLoader } from 'react-spinners';

const FeatureDisplay = ({ page, t, lng, project }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [content, setContent] = useState({ images: [], descriptions: [] });
  const [magnifierStyle, setMagnifierStyle] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      let featureContent;
      if (project === 'Origym') {
        featureContent = await import('../../assets/JSON/Origym.json');
      } else if (project === 'PetMatch') {
        featureContent = await import('../../assets/JSON/PetMatch.json');
      }
      if (featureContent && featureContent.default[page]) {
        const images = featureContent.default[page].images[lng];
        const descriptions = featureContent.default[page].descriptions[lng];
        setContent({ images, descriptions });
      }
    };

    loadContent();
  }, [page, lng]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const magnifierSize = 150; // Size of the magnifier
    const halfMagnifierSize = magnifierSize / 2;

    const x = e.pageX - left - window.pageXOffset;
    const y = e.pageY - top - window.pageYOffset;

    const posX = Math.max(halfMagnifierSize, Math.min(width - halfMagnifierSize, x));
    const posY = Math.max(halfMagnifierSize, Math.min(height - halfMagnifierSize, y));

    const bgPosX = (posX / width) * 100;
    const bgPosY = (posY / height) * 100;

    setMagnifierStyle({
      left: `${posX - halfMagnifierSize}px`,
      top: `${posY - halfMagnifierSize}px`,
      backgroundImage: `url(${Array.isArray(content.images) ? content.images[imageIndex]?.url : content.images.url})`,
      backgroundPosition: `${bgPosX}% ${bgPosY}%`,
    });
  };

  const { images, descriptions } = content;

  const currentDescriptions = descriptions && descriptions[0]; // Only use the first set of descriptions

  return (
    <div className={styles.mainSection}>
      <section className={styles.imageSection}>
        <div className={styles.imageContainer}>
          <div className={styles.image} onMouseMove={handleMouseMove}>
            {loading && <div className={styles.loader}><FadeLoader color='FFFFFF' /></div>}
            {images && (
              <img
                src={Array.isArray(images) ? images[imageIndex]?.url : images.url}
                alt={`feature`}
                onLoad={handleImageLoad}
              />
            )}
            <div className={styles.magnifier} style={magnifierStyle}></div>
          </div>
        </div>

        {/* Descriptions Section */}
        <div className={styles.descriptions}>
          {currentDescriptions && (
            <>
              <div className={styles.description}>
                <div className={styles.iconContainer}><img src={UI} alt="UI Icon" /><h3>{t("UIDesign")}</h3></div>
                <p>{currentDescriptions[0]}</p>
              </div>
              <div className={styles.description}>
                <div className={styles.iconContainer}><img src={UX} alt="UX Icon" /><h3>{t("UXDesign")}</h3></div>
                <p>{currentDescriptions[1]}</p>
              </div>
              <div className={styles.description}>
                <div className={styles.iconContainer}><img src={frontEnd} alt="Frontend Icon" /><h3>{t("Development")}</h3></div>
                <p>{currentDescriptions[2]}</p>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default FeatureDisplay;