import React, { useState, useEffect } from 'react';
import styles from "./Origym.module.css";
import siteMapEn from "./sitemap_en.png";
import siteMapPt from "./sitemap_pt.png";
import FeatureDisplay from '../../../components/FeatureDisplay/FeatureDisplay';
import hero from "../../../assets/origym_hero.png";

const Origym = ({ t, lng }) => {
  const [selectedPage, setSelectedPage] = useState('Landing');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pages = [
    { id: 'Landing', label: t("Landing") },
    { id: 'Register', label: t("Register") },
    { id: 'Login', label: t("Login") },
    { id: 'Onboarding', label: t("Onboarding") },
    { id: 'Home', label: t("Home") },
    { id: 'Workouts', label: t("Workouts") },
    { id: 'Nutrition', label: t("Nutrition") },
    { id: 'FriendsFeed', label: t("Friends/Feed") },
    { id: 'Profile', label: t("Profile") },
    { id: 'Settings', label: t("Settings") },
  ];

  return (
    <div>
      <section className={styles.heroSection}>
        <header className={styles.title}>
          <h2>ORIGYM</h2>
        </header>
        <img src={hero} alt="hero image"/>
       
      </section>
      
      <section className={styles.overview}> 
        <header className={styles.title}>
          <h2>{t("projectOverview")}</h2>
        </header>
        <p>{t("origym_overview")}</p>
        <p><strong>{t("keyFeatures")}:</strong></p>
        <p><strong>{t("origym_bullet1_title")}:</strong> {t("origym_bullet1_text")}</p>
        <p><strong>{t("origym_bullet2_title")}:</strong> {t("origym_bullet2_text")}</p>
        <p><strong>{t("origym_bullet3_title")}:</strong> {t("origym_bullet3_text")}</p>
      </section>

      <section className={styles.siteMapSection}>
        <header className={styles.title}>
          <h2>{t("SiteMap")}</h2>
        </header>
        <img className={styles.siteMapImage} src={lng === 'pt' ? siteMapPt : siteMapEn} alt='map of the Origym Website' />
      </section>

      <section className={styles.pagesSection}>
        <header className={styles.title}>
          <h2>{t("pages")}</h2>
        </header>
        <div className={styles.buttonRow}>
          {pages.map((page) => (
            <button
              key={page.id}
              className={selectedPage === page.id ? styles.activeButton : styles.inactiveButton}
              onClick={() => setSelectedPage(page.id)}
            >
              {page.label}
            </button>
          ))}
        </div>
        <div className={styles.pageDisplay}>
          <FeatureDisplay project="Origym" t={t} lng={lng} page={selectedPage} />
        </div>
      </section>
    </div>
  );
};

export default Origym;