import React from 'react';
import { useParams } from 'react-router-dom';
import './Homepage.css';
import Skills from '../../components/SkillsAndHabilities/Skills';
import LatestProjects from '../../components/LatestProjects/LatestProjects';
import profileImage from "./julio 1.png";
import helloImage from "./hello.png";

const Homepage = ({ t }) => {
  // Get the URL parameter using useParams
  const { page } = useParams(); // 'page' will be whatever parameter you use in your routing

  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero-section">
        <img className="profile-image" src={profileImage} alt="Profile" />

        <p>{t("I'm")} Julio Kugler</p>
        <img className="hello-image" src={helloImage} alt="Hello" />

        {/* If the URL is "/contato", show contact buttons */}
        {page === "contact" ? (
         <p className="profile-description">
         <button>WhatsApp</button>
         <button>LinkedIn</button>
         <button>E-mail</button>
         <button>GitHub</button>
       </p>
        ) : (
          
           <p className="profile-description">
           {t("Front-End Developer / UI and UX Designer")}
           <br />
           {t("Based in São Paulo, Brazil")}
         </p>
        )}

        <p className="hero-text">
          <span>THINK. RESEARCH. CREATE.</span>
        </p>
      </section>

      <p className="profile-subtext">
      {t("subtext-profile")}
        <br /> {t("subtext-profile2")}
      </p>

      {/* Cards Section */}
      <section className="cards-section">
        <Skills t={t} />
      </section>

      <section className="projects-section">
        <LatestProjects t={t} />
      </section>
    </div>
  );
};

export default Homepage;