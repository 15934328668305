import React, { useState, useEffect } from 'react';
import styles from "./PetMatch.module.css";
import siteMapEn from "./sitemap_en.png";
import siteMapPt from "./sitemap_pt.png";
import dsystem from "./DesignSystem.png";
import FeatureDisplay from '../../../components/FeatureDisplay/FeatureDisplay';
import hero from "../../../assets/petMatch_hero.png";

const PetMatch = ({ t, lng }) => {
  const [selectedPage, setSelectedPage] = useState('Landing');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pages = [
    { id: 'Landing', label: t("Landing") },
    { id: 'Register', label: t("Register") },
    { id: 'Login', label: t("Login") },
    { id: 'Adopt', label: t("Adopt") },
    { id: 'petDetails', label: t("petDetails") },
    { id: 'Announce', label: t("Announce") },
    { id: 'Help', label: t("Help") },
    { id: 'About', label: t("About") },
    { id: 'FAQ', label: t("FAQ") },
    { id: 'Blog', label: t("Blog") },
  ];

  return (
    <div>
      <section className={styles.heroSection}>
        <header>
          <h2  className={styles.title}>PETMATCH</h2>
        </header>
        <img src={hero} alt="hero image" />
        </section>
        <section className={styles.overview}>
        <header>
          <h2 className={styles.title}>{t("projectOverview")}</h2>
        </header>
        <p>{t("petmatch_overview")}</p>
        <p><strong>{t("keyFeatures")}:</strong></p>
        <p><strong>{t("petmatch_bullet1_title")}:</strong> {t("petmatch_bullet1_text")}</p>
        <p><strong>{t("petmatch_bullet2_title")}:</strong> {t("petmatch_bullet2_text")}</p>
        <p><strong>{t("petmatch_bullet3_title")}:</strong> {t("petmatch_bullet3_text")}</p>
      </section>

      <section className={styles.siteMapSection}>
        <header >
        <h2 className={styles.title}>{t("SiteMap")}</h2>
        </header>
        <img className={styles.siteMapImage} src={lng === 'pt' ? siteMapPt : siteMapEn} alt='map of the PetMatch Website' />
      </section>

      <section className={styles.pagesSection}>
        <header >
        <h2 className={styles.title}>{t("pages")}</h2>
        </header>
        <div className={styles.buttonRow}>
          {pages.map((page) => (
            <button
              key={page.id}
              className={selectedPage === page.id ? styles.activeButton : styles.inactiveButton}
              onClick={() => setSelectedPage(page.id)}
            >
              {page.label}
            </button>
          ))}
        </div>
        <div className={styles.pageDisplay}>
          <FeatureDisplay project="PetMatch" t={t} lng={lng} page={selectedPage} />
        </div>
      </section>

      <section className={styles.siteMapSection}>
        <header className={styles.title}>
          <h2>{t("Style Guide")}</h2>
        </header>
        <img className={styles.siteMapImage} src={dsystem} alt='Design System' />
      </section>
    </div>
  );
};

export default PetMatch;